@font-face {
  font-family: 'Nexa';
  src: url('../assets/font/Nexa/Nexa-Regular.eot');
  src: url('../assets/font/Nexa/Nexa-Regular.eot');
  src:
    local('Nexa Regular'),
    local('Nexa-Regular'),
    url('../assets/font/Nexa/Nexa-Regular.eot?#iefix') format('embedded-opentype'),
    url('../assets/font/Nexa/Nexa-Regular.woff2') format('woff2'),
    url('../assets/font/Nexa/Nexa-Regular.woff') format('woff'),
    url('../assets/font/Nexa/Nexa-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Nexa';
  src: url('../assets/font/Nexa/Nexa-Heavy.eot');
  src:
    local('Nexa Bold'),
    local('Nexa-Bold'),
    url('../assets/font/Nexa/Nexa-Bold.eot?#iefix') format('embedded-opentype'),
    url('../assets/font/Nexa/Nexa-Bold.woff2') format('woff2'),
    url('../assets/font/Nexa/Nexa-Bold.woff') format('woff'),
    url('../assets/font/Nexa/Nexa-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Nexa';
  src: url('../assets/font/Nexa/Nexa-Heavy.eot');
  src:
    local('Nexa Heavy'),
    local('Nexa-Heavy'),
    url('../assets/font/Nexa/Nexa-Heavy.eot?#iefix') format('embedded-opentype'),
    url('../assets/font/Nexa/Nexa-Heavy.woff2') format('woff2'),
    url('../assets/font/Nexa/Nexa-Heavy.woff') format('woff'),
    url('../assets/font/Nexa/Nexa-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
