@charset "utf-8";

@import 'variables';
@import 'vendor';
@import 'spacing';
@import 'fonts';

body {
  overflow-y: hidden;
  margin: 0;
}

.cdk-global-scrollblock {
  overflow-y: auto;
}

strong {
  font-weight: 500;
}

.d-none {
  display: none !important;
}

.material-icon-gray {
  &:not(.mat-button-disabled .material-icon-gray) {
    color: $gray-text;
  }

  &--hover {
    &:hover {
      color: rgba(black, 0.87);
    }
  }
}

.mat-mdc-icon-button:not(.mat-button-disabled):hover .material-icon-gray {
  color: rgba(black, 0.87);
}

.mat-mdc-card:not(.mat-mdc-card-with-shadow) {
  box-shadow: none !important;
}

.search-content {
  padding: 0 8px !important;
}

.overlay {
  display: inline-block;
  position: relative;
  z-index: 1;
}

.overlay ::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 0 999px rgba(0, 0, 0, 0.8);
}

.fab-add {
  position: fixed !important;
  z-index: 2;
  right: 24px;
  bottom: 24px;
}

// Use this class in elements that should be stretched to 100% of available height
// and inner element will handle scrolling. Good example can be views with
// full height data table or calendar views.
.full-height {
  height: 100%;
  display: flex;
  flex-direction: column;
}

// Use this class on scrollable area inside full height class for
// consistant padding.
.full-height-content {
  padding: 16px;
  overflow: auto;
}

.auth {
  font-weight: 200;

  .mat-mdc-raised-button {
    box-shadow: none !important;
    text-transform: none !important;
    font-weight: 300 !important;
  }

  .mat-toolbar {
    background: white !important;
    color: #424242 !important;
  }

  .mat-toolbar h2 {
    font-weight: 300 !important;
  }
}

.clickable {
  cursor: pointer !important;
}

.calendar-list {
  .mat-mdc-radio-button label {
    white-space: normal !important;
    word-break: break-all;
    // Overwrite in Chrome - better alternative
    word-break: break-word;
  }
}

.context-menu {
  .mat-mdc-menu-content {
    padding: 0 !important;
  }
}

.nowrap {
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.break-word {
  word-break: break-all;
  word-break: break-word;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.clover-footer {
  display: none;
}

.login-menu {
  & .menu-form-wrapper .mat-mdc-menu-content:not(:empty) {
    padding: 0;
  }
}

.menu-form-wrapper .menu-form {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.d-block {
  display: block;
}

.custom-dialog-container .mat-mdc-dialog-container {
  padding: 0 !important;
}

.document-markup {
  &.fullscreen {
    width: 100vw !important;
    height: 100vh !important;
    max-height: 100vh !important;
    max-width: 100vw !important;
  }
  mat-dialog-container {
    display: flex !important;
  }
}

::-webkit-scrollbar {
  width: 1.2rem;
  height: 1.2rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  box-shadow: inset 0 0 0 1rem rgba(155, 155, 155, 0.5);
  border: solid 0.3rem transparent;
}

::-webkit-scrollbar-thumb:hover {
  box-shadow: inset 0 0 0 1rem #9b9b9b;
}
