.synply-loader {
  &__animation {
    animation-name: breathing;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    transform-origin: 50% 50%;
    animation-duration: 1s;
    display: inline-block;
  }
}

@keyframes breathing {
  0% {
    transform: scale(0.9);
  }

  25% {
    transform: scale(1);
  }

  60% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(0.9);
  }
}

#synply-loader {
  position: fixed;
  top: 50%;
  bottom: 0;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 100px;
  margin: auto;
  text-align: center;
}
