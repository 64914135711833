@use '@angular/material' as mat;
$navigation-drawer-width: auto;

$mat-form-field-default-infix-width: 180px !default;

@import 'variables';

@include mat.all-component-typographies();
@include mat.core();

$qt-blue: (
  100: $color-base-lighter-blue,
  500: $color-base-light-blue,
  600: $color-base-blue,
  700: $color-base-dark-blue,
  800: $color-base-dark-blue,
  contrast: (
    100: $gray-text,
    500: $gray-text,
    600: white,
    700: white,
    800: white,
  ),
);

$qt-accent: (
  100: $color-accent-lighter,
  500: $color-accent-light,
  600: $color-accent,
  700: $color-accent-dark,
  800: $color-accent-dark,
  contrast: (
    100: $gray-text,
    500: $gray-text,
    600: $gray-text,
    700: $gray-text,
    800: $gray-text,
  ),
);

$primary-light: mat.define-palette($qt-blue, 600);
$accent-light: mat.define-palette($qt-accent, 600);

$primary-dark: mat.define-palette(mat.$grey-palette, 800);
$accent-dark: mat.define-palette(mat.$amber-palette, 800);

$theme:  mat.define-light-theme(
  (
    color: (
      primary: $primary-light,
      accent: $accent-light,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);
$dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $primary-dark,
      accent: $accent-dark,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);;

@mixin qt-typography($config: null) {
  @if $config == null {
    $config: mat.define-typography-config();
  }
}

@mixin qt-theme($theme) {
  // Include typography in theme.
  @include qt-typography();
}

@include mat.all-component-themes($theme);
@include qt-theme($theme);

:root {
  --layout-breakpoint-xs: #{$layout-breakpoint-xs};
  --layout-breakpoint-sm: #{$layout-breakpoint-sm};
  --layout-breakpoint-md: #{$layout-breakpoint-md};
  --layout-breakpoint-lg: #{$layout-breakpoint-lg};

  --color-shamrock: #{$color-shamrock};
  --color-cinnabar: #{$color-cinnabar};
  --color-ebony-clay: #{$color-ebony-clay};
  --color-error: #{$color-error};
  --color-success: #{$color-success};
  --color-accent: #{$color-accent};
  --color-accent-light: #{$color-accent-light};
  --color-accent-20: #{$color-accent-20};
  --color-base: #{$color-base};
  --color-warn: #{$color-warn};
  --color-base-light: #{$color-base-light};
  --gray-text: #{$gray-text};
}

button {
  &[mat-button],
  &[mat-raised-button] {
    text-transform: uppercase;
  }
}

/**
 * This style can not be placed inside data-table stylesheet
 * because icons are dynamic and can come from ng-template element,
 * which is outside of that component"s scope.
 */
.td-data-table-cell {
  .mat-icon:not(.iconColor) {
    color: rgba(0, 0, 0, 0.54);
  }

  > * {
    vertical-align: middle;
  }
}

.td-data-table.small {
  > thead > tr {
    height: 25px;
  }

  > tbody > tr {
    height: 40px;

    > td:first-child {
      padding: 0;
    }
  }
}

.mat-mdc-dialog-container {
  will-change: transform;
  position: relative;
  margin: 0 auto;

  .mat-toolbar {
    margin: -#{$dialog-padding};
    margin-bottom: $dialog-padding;
    width: auto !important;
  }
}

@mixin fullscreen-dialog {
  width: 100vw !important;
  height: 100vh !important;
  max-width: none !important;

  > .mat-mdc-dialog-container {
    max-width: none;
    width: 100%;
    height: 100%;

    .mat-mdc-dialog-content {
      flex: 1;
      padding: 0 $dialog-padding;
      max-height: none;
    }

    .mat-mdc-dialog-actions {
      padding: 12px $dialog-padding;
      margin: 0 -#{$dialog-padding} -#{$dialog-padding};
      border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
}

@mixin iphone-dialog {
  // iPhone 4 and 4S
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    height: calc(100vh - 70px) !important;
  }

  // iPhone 5 and 5S
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    height: calc(100vh - 70px) !important;
  }

  // iPhone 6 and iPhone 7
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    height: calc(100vh - 70px) !important;
  }

  // iPhone 6+ and iPhone 7+
  @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
    height: calc(100vh - 70px) !important;
  }
}

.fullscreen {
  @media (max-width: $layout-breakpoint-xs) {
    @include fullscreen-dialog;
  }

  @include iphone-dialog;
}

.fullscreenAll {
  @include fullscreen-dialog;
  // @include iphone-dialog;
}

.mat-toolbar {
  position: relative;
  flex-shrink: 0;
  height: 54px;

  > .mat-mdc-fab {
    z-index: 2;
    position: fixed;
    right: 24px;
    bottom: 24px;
  }

  // Hide fab when search is active.
  > .search--active ~ .mat-mdc-fab {
    display: none;
  }

  &.mat-transparent {
    background-color: transparent !important;
    color: rgba(0, 0, 0, 0.87);
  }
}

.qt-dialog-content {
  display: block;
}

// Styles for <hr> element inside <md-menu>
.mat-menu-divider {
  margin: 8px 0;
  border: none;
  background-color: rgba(0, 0, 0, 0.12);
  height: 1px;
}

.mat-mdc-form-field {
  width: initial;
}

.mat-mdc-form-field-infix {
  width: $mat-form-field-default-infix-width;
}

.mat-mdc-header-cell,
.mat-mdc-cell {
  &:not(:first-child) {
    padding-left: 6px;
  }
}

.mat-toolbar {
  &--border {
    border-bottom: 1px solid $color-base-lighter;
  }

  &--border-divider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
}
